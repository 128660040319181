import cat from './Database/cat.json'
import dog from './Database/dog.json'
import lion from './Database/lion.json'
import fox from './Database/fox.json'
import rabbit from './Database/rabbit.json'
import leopard from './Database/leopard.json'
import bird from './Database/bird.json'
import flower from './Database/flower.json'
import snake from './Database/snake.json'
import thigh from './Database/thigh.json'
import arm from './Database/arm.json'

const database={
    cat,
    dog,
    lion,
    fox,
    rabbit,
    leopard,
    bird,
    flower,
    snake,
    thigh,
    arm
}

export default database