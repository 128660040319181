import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';


const items = [
  { name: 'Cat/Katze', emoji: '🐱', link: '/cat' },
  { name: 'Dog/Hund', emoji: '🐶', link: '/dog' },
  { name: 'Lion/Löwe', emoji: '🦁', link: '/lion' },
  { name: 'Fox/Fuchs', emoji: '🦊', link: '/fox' },
  { name: 'Rabbit/Kaninchen', emoji: '🐰', link: '/rabbit' },
  { name: 'Leopard/Leopard', emoji: '🐆', link: '/leopard' },
  { name: 'Bird/Vogel', emoji: '🐦', link: '/bird' },
  { name: 'Flower/Blume', emoji: '🌸', link: '/flower' },
  { name: 'Snake/Schlange', emoji: '🐍', link: '/snake' },
  { name: 'Thigh/Schenkel', emoji: '🦵', link: '/thigh' },
  { name: 'Arm/Arm', emoji: '💪', link: '/arm' },
];

const Home = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-100 to-pink-100 p-4">
      <div className="w-full max-w-md">
        <h1 className="text-4xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 font-serif">
          Tattoo Collection
        </h1>
        <div className="bg-white rounded-2xl overflow-hidden shadow-2xl">
          <ul className="divide-y divide-gray-200">
            {items.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.link}
                  className="flex items-center px-6 py-4 hover:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <span className="text-2xl mr-4">{item.emoji}</span>
                  <span className="flex-grow text-gray-800 font-medium">{item.name}</span>
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;

// const Home = () => {

//   const words= ['React', 'Tailwind', 'JavaScript', 'CSS', 'HTML', 'Node.js', 'Express', 'MongoDB', 'GraphQL', 'TypeScript', 'Vue', 'Angular', 'Svelte', 'Next.js', 'Gatsby'] 
//   const getRandomPosition = () => {
//     return {
//       left: `${Math.random() * 80}%`,
//       top: `${Math.random() * 80}%`,
//     };
//   };

//   const getRandomColor = () => {
//     const colors = ['text-blue-500', 'text-green-500', 'text-red-500', 'text-purple-500', 'text-yellow-500'];
//     return colors[Math.floor(Math.random() * colors.length)];
//   };

//   const getRandomSize = () => {
//     const sizes = ['text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl'];
//     return sizes[Math.floor(Math.random() * sizes.length)];
//   };

//   return (
//     <div className="relative w-full h-96 bg-gray-100 rounded-lg shadow-lg overflow-hidden">
//       {words.map((word, index) => (
//         <span
//           key={index}
//           className={`absolute ${getRandomColor()} ${getRandomSize()} font-semibold transform hover:scale-110 transition-transform duration-200 cursor-pointer`}
//           style={getRandomPosition()}
//         >
//           {word}
//         </span>
//       ))}
//     </div>
//   );
// };

// export default Home;

