import React, { useState, useEffect, useCallback } from 'react';
import { X, Upload, Loader } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';
import { Button } from './ui/button';
import ImageGallery from './ImageGallery';
import axios from 'axios';
import { useToken } from '../TokenContext';
import { useNameSpace } from '../NameSpaceContext';
import { useDatabase } from '../DatabaseContext';

import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';








// Updated ImageUploader component
const ImageUploader = () => {
  const navigate = useNavigate();
  const { animal } = useParams()

  console.log(animal)

  const { token, setToken } = useToken();
  const { NameSpace, setNameSpace } = useNameSpace();
  const { Database, setDatabase } = useDatabase();

  console.log(Database)
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageDescription, setSelectedImageDescription] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);


  const [isDragging, setIsDragging] = useState(false);


  const [searchInput, setSearchInput] = useState('');
  

  return (
    
     
     <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Navigation Bar */}
      <div className="bg-gray-50 border-b border-gray-200">
        <div className="fixed top-0 left-0 right-0 bg-gray-50 border-b border-gray-200 z-10">
          <div className="max-w-md mx-auto px-4 py-3 flex items-center justify-between">
            <button
              onClick={() => navigate(-1)}
              className="text-blue-500 font-semibold flex items-center"
            >
              <svg className="h-5 w-5 mr-1" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              Back
            </button>
            <h2 className="text-center text-lg font-semibold">{animal}</h2>
            <div className="w-14"></div> {/* Spacer for centering */}
          </div>
        </div>
      
      
        <div className="pt-16 px-4 pb-8 max-w-md mx-auto">
         <ImageGallery searchResult={Database[animal].receivedContent.map(i=>i.metadata)}  />
        </div>
      
      </div>


    </div>     

  );
};

export default ImageUploader;