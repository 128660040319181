// ScrollContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import database from './database';


const DatabaseContext = createContext();

export const DatabaseProvider = ({ children }) => {
  const [Database, setDatabase] = useState(database);

  return (
    <DatabaseContext.Provider value={{ Database, setDatabase }}>
      {children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => useContext(DatabaseContext);
