// ScrollContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const NameSpaceContext = createContext();

export const NameSpaceProvider = ({ children }) => {
  const [NameSpace, setNameSpace] = useState([]);

  return (
    <NameSpaceContext.Provider value={{ NameSpace, setNameSpace }}>
      {children}
    </NameSpaceContext.Provider>
  );
};

export const useNameSpace = () => useContext(NameSpaceContext);
