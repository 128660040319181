import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { TokenProvider } from './TokenContext';
import { NameSpaceProvider } from './NameSpaceContext';
import { DatabaseProvider } from './DatabaseContext';

import Animal from './components/Animal';
import Config from './components/Config';
import Home from './components/Home';


function App() {

  return (



    <div className="App">


      <BrowserRouter>
        <TokenProvider>
        <NameSpaceProvider>
        <DatabaseProvider>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/:animal" element={<Animal />}></Route>

            {/* <Route path="/config" element={<Config />}></Route> */}

          </Routes>
        </DatabaseProvider>
        </NameSpaceProvider>
        </TokenProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;