import React, { useState, useEffect } from 'react';
import moment from "moment"
import './ImageGallery.css'



// New ImageGallery component
const ImageGallery = ({searchResult}) => {
    return (
      <div className="card-container">

              
            {searchResult.map(i=>{
              return (
              <div key={i.postLink}  className="card" >
                <a href={i.postLink} target="_blank">
                  
                  <img src={i.AzureImageLink} alt="Card Image" className="card-image" />
                </a>
                <div className="card-content">
                    {/* <h2 className="card-title">{moment(i.date, "YYYY年M月DD日").format("MMMM D, YYYY")}</h2> */}
                    <p className="card-description">{i.imageShortDescription}</p>
                </div>
              </div>
              )
            })}
            
        </div>
    );
  };

  export default ImageGallery